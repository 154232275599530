import * as PIXI from 'pixi.js'
import Grass from './assets/grass.jpg'
import {buildGrid, buildSquares, createFrame} from "./draw-utils";

let app;

export function update(frame, width, height, squares, squareSize) {
    buildSquares(frame, squares, width, height, squareSize)

    // const now = new Date().getTime()
    // positions.forEach((p => {
    //     // get the correct square
    //     // round down to square size
    //
    //
    //     const square = squaresMap[`${x}_${y}`]
    //     if (square){
    //         square.lastUpdate = now
    //         square.isTouched = true
    //         square.graphic.alpha = 0.0
    //         // square.graphic.clear()
    //         // square.graphic.beginFill(0xFF0000);
    //         // square.graphic.drawRect(x, y, squareSize, squareSize);
    //         // square.graphic.endFill();
    //     }
    // }))
}

export default function (width, height, squares, grid, squareSize) {

    app = new PIXI.Application({backgroundColor: 0xFFFFFF, width: width, height: height, resizeTo: document.body});

    // const background = PIXI.Sprite.from(Grass)
    app.stage.addChild( squares, grid);

    // app.ticker.add((delta) => {
    //     const now = new Date().getTime()
    //     squares.forEach((s) => {
    //         if(s.graphic.alpha < 1.0 && ((now - s.lastUpdate) > 500)){
    //             s.graphic.alpha += 0.02 * delta
    //             // s.graphic.alpha = s.alpha
    //
    //         }
    //     })
    // })
    return app;
};

