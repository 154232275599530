import './style.css'
import * as facemesh from '@tensorflow-models/facemesh'
import * as tf from '@tensorflow/tfjs-core';
import debounce from 'lodash.debounce'

import createScene, {update as updateScene} from './scene-2d.js'
import {buildGrid, buildSquares, COLOR_MAP, createFrame} from "./draw-utils";

const SCALE_FACTOR = 2
const SQUARE_SIZE = 20
let noVideo = false
let animationId;
let rect;
let app;
let video;
let videoWidth;
let videoHeight;
let minX;
let maxX;
let minY;
let maxY;
let displayBoundingBox;
let model;
let squares;
let grid;


// Watch for browser/canvas resize events
const resizer = debounce(async function handleResize (){
    window.removeEventListener('resize', resizer)
    window.location.reload()
    // console.log('resizing')
    // try{
    //     await init()
    // } catch (e) {
    //     console.log('something wrong', e)
    // }
}, 500)
window.addEventListener("resize",resizer);

async function init() {
    rect = document.body.getBoundingClientRect()
    squares = buildSquares(null, null, rect.width, rect.height, SQUARE_SIZE)
    grid = buildGrid(rect.width, rect.height, null, SQUARE_SIZE)
    if (app){
        app.renderer.resize(rect.width, rect.height)
        squares = buildSquares(null, squares, rect.width, rect.height, SQUARE_SIZE)
        grid = buildGrid(rect.width, rect.height, grid, SQUARE_SIZE)
        // document.body.innerHTML = ''
        // document.querySelectorAll('canvas')
    } else {
        app = createScene(rect.width, rect.height, squares, grid, SQUARE_SIZE);
    }

    videoWidth = rect.width / SCALE_FACTOR;
    videoHeight = rect.height / SCALE_FACTOR;
    minX = 0.1 * rect.width
    maxX = 0.9 * rect.width
    minY = 0.1 * rect.height
    maxY = 0.9 * rect.height

    displayBoundingBox = {
        topLeft: [minX, minY],
        bottomRight: [maxX, maxY],
        center: [(minX + maxX) / 2, (minY + maxY) / 2]
    }

    document.body.appendChild(app.view);
    app.view.removeEventListener('mousemove', handleMouseMove)
    app.view.addEventListener("mousemove", handleMouseMove);

    try {
        video = await loadVideo();
    } catch (e) {
        console.error()
        noVideo = true
        // let info = document.getElementById('info');
        // info.textContent = 'this browser does not support video capture,' +
        //     'or this device does not have a camera';
        // info.style.display = 'block';
    }

    if (!model){
        // console.log('awaiting model again')
        try {
            // Load the MediaPipe facemesh model.
            await tf.setBackend('webgl')
            model = await facemesh.load({maxFaces: 1});

        } catch (e) {
            // console.log('something wrong with face detection', e)
        }
    }
    if (animationId){
        // console.log('cancelling animation')
        window.cancelAnimationFrame(animationId)
        animationId = null
    }

    if (video && model && !animationId){
        await detectFaceInRealTime(video, model);
    }


}

function handleMouseMove() {
    if (noVideo) {
        const frame = createFrame(rect.width, rect.height, SQUARE_SIZE)
        const x = Math.floor(e.pageX / SQUARE_SIZE)
        const y = Math.floor(e.pageY / SQUARE_SIZE)
        frame[x][y] = {color: 0xFF00FF, alpha: 0.0}

        updateScene(frame, rect.width, rect.height, squares, SQUARE_SIZE)
    }
}

/**
 * Loads a the camera to be used in the demo
 *
 */
async function setupCamera() {
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
        throw new Error(
            'Browser API navigator.mediaDevices.getUserMedia not available');
    }

    let video = document.getElementById('video')
    if (video !== null){
        document.body.removeChild(video)
    }

    video = document.createElement('video');
    video.className = 'debug-video'
    video.playsInline = true;
    video.id = 'video';
    video.width = videoWidth;
    video.height = videoHeight;

    video.style.display = 'none'

    document.body.appendChild(video)

    // const mobile = isMobile();
    const stream = await navigator.mediaDevices.getUserMedia({
        'audio': false,
        'video': {
            facingMode: 'user',
            width: videoWidth,
            height: videoHeight,
        },
    });
    video.srcObject = stream;

    return new Promise((resolve) => {
        video.onloadedmetadata = () => {
            resolve(video);
        };
    });
}

function getScale(boundingBox) {

    const scaleX = (displayBoundingBox.bottomRight[0] - displayBoundingBox.topLeft[0]) / (boundingBox.bottomRight[0][0] - boundingBox.topLeft[0][0])
    const scaleY = (displayBoundingBox.bottomRight[1] - displayBoundingBox.topLeft[1]) / (boundingBox.bottomRight[0][1] - boundingBox.topLeft[0][1])
    return scaleX < scaleY ? scaleX : scaleY
}

function mapCoord(boundingBox, boundingBoxScaled, coord, scale, color, frame) {
    // subtract left from each coord, normalising to 0,0
    let x = coord[0] - boundingBox.topLeft[0][0]
    let y = coord[1] - boundingBox.topLeft[0][1]

    // scale into target dimensions
    x = x * scale
    y = y * scale

    // translate into display center
    x = Math.floor((x + displayBoundingBox.center[0] - 0.5 * boundingBoxScaled.width) / SQUARE_SIZE)
    y = Math.floor((y + displayBoundingBox.center[1] - 0.5 * boundingBoxScaled.height) / SQUARE_SIZE)

    if (frame[x] && frame[x][y]) {
        frame[x][y] = {color: color}
    }
}

async function loadVideo() {
    const video = await setupCamera();
    video.play();

    return video;
}

const eyeNames = ['rightEyeUpper0', 'rightEyeUpper1', 'rightEyeUpper2', 'rightEyeUpper3', 'rightEyeLower0', 'rightEyeLower1', 'rightEyeLower2', 'rightEyeLower3',
    'leftEyeUpper0', 'leftEyeUpper1', 'leftEyeUpper2', 'leftEyeUpper3', 'leftEyeLower0', 'leftEyeLower1', 'leftEyeLower2', 'leftEyeLower3']

async function detectFaceInRealTime(video, model) {

    // Pass in a video stream (or an image, canvas, or 3D tensor) to obtain an
    // array of detected faces from the MediaPipe graph.

    async function faceDetectionFrame() {

        const predictions = await model.estimateFaces(video);

        const frame = createFrame(rect.width, rect.height, SQUARE_SIZE, {color: 0xFFFFFF})

        if (predictions.length > 0 && predictions[0].annotations) {
            const boundingBox = predictions[0].boundingBox;
            const silhouette = predictions[0].annotations.silhouette;
            const leftCheek = predictions[0].annotations.leftCheek //cheeks have only one coord
            const rightCheek = predictions[0].annotations.rightCheek
            const eyeCenter = predictions[0].annotations.midwayBetweenEyes
            const noseBottom = predictions[0].annotations.noseBottom
            const lips = predictions[0].annotations.lipsUpperOuter;

            Array.prototype.push.apply(lips, predictions[0].annotations.lipsLowerOuter)

            const eyes = []

            eyeNames.forEach(name => {
                Array.prototype.push.apply(eyes, predictions[0].annotations[name])
            })


            const scale = getScale(boundingBox)
            const boundingBoxScaled = {
                topLeft: [boundingBox.topLeft[0][0] * scale, boundingBox.topLeft[0][1] * scale],
                bottomRight: [boundingBox.bottomRight[0][0] * scale, boundingBox.bottomRight[0][1] * scale],
            }
            boundingBoxScaled.width = boundingBoxScaled.bottomRight[0] - boundingBoxScaled.topLeft[0]
            boundingBoxScaled.height = boundingBoxScaled.bottomRight[1] - boundingBoxScaled.topLeft[1]

            // leftCheek.forEach(coord => {
            //     mapCoord(boundingBox, boundingBoxScaled, coord, scale, 0x00FF00, frame)
            // })

            // rightCheek.forEach(coord => {
            //     mapCoord(boundingBox, boundingBoxScaled, coord, scale, 0xFF00FF, frame)
            // })

            // eyeCenter.forEach(coord => {
            //     mapCoord(boundingBox, boundingBoxScaled, coord, scale, 0xFF0000, frame)
            // })

            // noseBottom.forEach(coord => {
            //     mapCoord(boundingBox, boundingBoxScaled, coord, scale, 0xFFFF00, frame)
            // })

            silhouette.forEach(coord => {
                mapCoord(boundingBox, boundingBoxScaled, coord, scale, COLOR_MAP.SILHOUETTE, frame)
            })

            lips.forEach(coord => {
                mapCoord(boundingBox, boundingBoxScaled, coord, scale, COLOR_MAP.LIPS, frame)
            })

            eyes.forEach(coord => {
                mapCoord(boundingBox, boundingBoxScaled, coord, scale, COLOR_MAP.EYES, frame)
            })

            updateScene(frame, rect.width, rect.height, squares, SQUARE_SIZE)

        } else {
            updateScene(frame, rect.width, rect.height, squares, SQUARE_SIZE)
        }

        return requestAnimationFrame(faceDetectionFrame);
    }
    animationId = await faceDetectionFrame().catch(console.log)
}

/**
 * Kicks off the demo by loading the posenet model, finding and loading
 * available camera devices, and setting off the detectPoseInRealTime function.
 */
export async function bindPage() {

    try {
        // Load the MediaPipe facemesh model.
        await tf.setBackend('webgl')
        model = await facemesh.load({maxFaces: 1});
        await detectFaceInRealTime(video, model);
    } catch (e) {
        console.log('something wrong with face detection', e)
    }
}

navigator.getUserMedia = navigator.getUserMedia ||
    navigator.webkitGetUserMedia || navigator.mozGetUserMedia;
init().catch(console.log);