import * as PIXI from "pixi.js";

export const COLOR_MAP = {
    SILHOUETTE: 0x000000,
    LIPS: 0x990000,
    EYES: 0x000099
}

export function buildGrid(width, height, graphics, squareSize){
    const grid = graphics ? graphics : new PIXI.Graphics()
    grid.clear()
    for(let x = 0; x < width; x = x + squareSize){
        grid.beginFill(0x0);
        grid.drawRect(x, 0, 2, height);
        grid.endFill();
    }

    for(let y = 0; y < height; y = y + squareSize){
        grid.beginFill(0x0);
        grid.drawRect(0, y, width, 2);
        grid.endFill();
    }
    return grid
}

export function roundDown(num, snap){
    return Math.floor(num / snap) * snap
}

export function createFrame(width, height, squareSize, defaultObject = {color: 0xFFFFFF}){

    const frame = []
    for(let x = 0; x < Math.ceil(width / squareSize); x++){
        frame.push([])
        for(let y = 0; y < Math.ceil(height / squareSize); y++){
            frame[x][y] = defaultObject
        }
    }
    return frame
}

export function buildSquares(frame, squaresGraphic, width, height, squareSize){
    const fr = frame ? frame : createFrame(width, height, squareSize)
    const graphic = squaresGraphic ? squaresGraphic : new PIXI.Graphics()

    graphic.clear()
    for(let x = 0; x < fr.length; x++){

        for(let y = 0; y < fr[x].length; y++){

            const item = fr[x][y]
            graphic.beginFill(item.color, item.alpha !== 1 ? item.alpha : undefined);
            graphic.drawRect(x * squareSize + 1, y * squareSize + 1, squareSize - 1, squareSize - 1);
            graphic.endFill();
            // squaresGraphic[`${x}_${y}`] = {graphic: square, alpha: 1}
        }
    }
    //
    // // color based on pos
    // positions.forEach(p => {
    //     const x = roundDown(p.x, squareSize)
    //     const y = roundDown(p.y, squareSize)
    //
    //     graphic.beginFill(p.color);
    //     graphic.drawRect(x + 1, y + 1, squareSize - 1, squareSize - 1);
    //     graphic.endFill();
    // })

    return graphic
}
